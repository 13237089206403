import { createTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import { csCZ, deDE, enUS, frFR, nlNL, roRO, skSK, svSE } from "@mui/material/locale";
import {
	csCZ as csCZ_datePickers,
	deDE as deDE_datePickers,
	enUS as enUS_datePickers,
	frFR as frFR_datePickers,
	nlNL as nlNL_datePickers,
	roRO as roRO_datePickers,
	skSK as skSK_datePickers,
	svSE as svSE_datePickers,
} from "@mui/x-date-pickers/locales";
import { cs, de, enUS as enUS_dateFns, frCH, nl, ro, sk, sv } from "date-fns/locale";
import { importAll, themeOptions } from "../core/theme";
import _config from "./config.js";
// services
import { UI_LANGUAGE } from "../../services/l10n";
import { mergeDeep } from "../../services/utils";
// types
import type { PaletteMode } from "@mui/material";
import type { Theme } from "@mui/material/styles";
import type { Locale } from "date-fns/locale";
import type { Config } from "../../types/config";

export { default as FLAGS } from "./flags";
export { default as icons } from "../core/icons";
export { images, hasImage } from "../core/theme";

export const config = _config as Config;

importAll(
	require.context("./icons", true, /\.(?:jpe?g|png|webp|avif|gif|svg|mp4)$/), // filter not possible to extract into a constant
	require.context("../core/icons", true, /\.(?:jpe?g|png|webp|avif|gif|svg|mp4)$/) // filter not possible to extract into a constant
);

const l10nDateFns = {
	cs: cs,
	de: de,
	en: enUS_dateFns,
	fr: frCH,
	nl: nl,
	ro: ro,
	sk: sk,
	sv: sv,
} as const satisfies Record<string, Locale>;
export const localeDateFns = l10nDateFns[UI_LANGUAGE] as Locale;

const l10nMuiTheme = {
	cs: csCZ,
	de: deDE,
	en: enUS,
	fr: frFR,
	nl: nlNL,
	ro: roRO,
	sk: skSK,
	sv: svSE,
} as const;

const l10nDatePickers = {
	cs: csCZ_datePickers,
	de: deDE_datePickers,
	en: enUS_datePickers,
	fr: frFR_datePickers,
	nl: nlNL_datePickers,
	ro: roRO_datePickers,
	sk: skSK_datePickers,
	sv: svSE_datePickers,
} as const;

const getDefaultTheme = (themeMode: PaletteMode) => {
	const defaultTheme = createTheme({
		palette: {
			mode: themeMode,
		},
	});
	return createTheme({
		palette: {
			...defaultTheme.palette,
			primary: {
				main: "#008fff",
			},
			neutral: {
				main: (themeMode === "dark") ? grey[800] : grey[300],
				contrastText: (themeMode === "dark") ? grey[300] : grey[900],
			},
			danger: {
				main: "#ee0000",
				dark: "#bb0000",
			},
		},
	});
};

let muiTheme: Theme;

export const refreshMuiTheme = (themeMode: PaletteMode) => {
	const defaultTheme = getDefaultTheme(themeMode);

	muiTheme = createTheme(mergeDeep(themeOptions, {
		palette: {
			...defaultTheme.palette,
			text: {
				primary: (themeMode === "dark") ? "#e0e0e0" : "#191919",
				secondary: (themeMode === "dark") ? "#949494" : "#828282",
			},
			background: {
				default: (themeMode === "dark") ? "#000000" : "#f2f2f2",
				paper: (themeMode === "dark") ? "#323232" : "#ffffff",
			},
			action: {
				disabledBackground: (themeMode === "dark") ? "rgba(255, 255, 255, 0.12)" : "#dcdcdc",
			},
		},
		components: {
			MuiAlert: {
				styleOverrides: {
					filledSuccess: {
						color: defaultTheme.palette.getContrastText((themeMode === "dark") ? defaultTheme.palette.success.dark : defaultTheme.palette.success.main),
					},
					filledInfo: {
						color: defaultTheme.palette.getContrastText((themeMode === "dark") ? defaultTheme.palette.info.dark : defaultTheme.palette.info.main),
					},
					filledWarning: {
						color: defaultTheme.palette.getContrastText((themeMode === "dark") ? defaultTheme.palette.warning.dark : defaultTheme.palette.warning.main),
					},
					filledError: {
						color: defaultTheme.palette.getContrastText((themeMode === "dark") ? defaultTheme.palette.error.dark : defaultTheme.palette.error.main),
					},
					outlinedError: {
						color: (themeMode === "dark") ? defaultTheme.palette.error.main : defaultTheme.palette.error.light,
					},
				},
			},
			MuiTab: {
				styleOverrides: {
					root: {
						"color": defaultTheme.palette.primary.main,
						"&.Mui-selected": {
							color: defaultTheme.palette.primary.contrastText,
							backgroundColor: defaultTheme.palette.primary.main,
						},
					},
				},
			},
		},
	}), themeOptions, l10nMuiTheme[UI_LANGUAGE], l10nDatePickers[UI_LANGUAGE]);
};

export const getMuiTheme = (themeMode: PaletteMode) => {
	refreshMuiTheme(themeMode);
	return muiTheme;
};

refreshMuiTheme("light");
